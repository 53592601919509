// 放置所有的请求接口地址
// const ipAddress = window.location.href.match(/(\d{1,3}\.){3}\d{1,3}/);
// const ip = ipAddress?ipAddress[0]:'127.0.0.1';
const ip = 'zyy.lg-iot.com';
// const ip = 'zyy.lg-iot.top';

// 公用地址
// const  url = `https://${ip}:20013/api/`
const  url = `https://${ip}/api/`

// 创建一个类
const urls = class{
    // 创建一个静态方法，不需要new调用，直接urls.m调用即可
    static m(){
        // 登录接口,通过模板语法拼接
        const login = `${url}login`
        // 获取本机网络信息接口
        const ipInfo = `${url}ipInfo`
        // 获取车位列表
        const parkingSpaceList = `${url}parkingSpaceList`
        // 更新车位位置信息
        const updatePosition = `${url}updatePosition`
        // 更新车位大小信息
        const updateSize = `${url}updateSize`
        // 获取相机列表
        const getCameraList = `${url}getCameraList`
        // 修改密码
        const changePassword = `${url}changePassword`
        // 获取区域列表
        const getRegionList = `${url}getRegionList`
        // 添加区域
        const addRegion = `${url}addRegion`
        // 编辑区域
        const editRegion = `${url}editRegion`
        // 删除区域
        const deleteRegion = `${url}deleteRegion`
        // 添加相机
        const addCamera = `${url}addCamera`
        // 删除单个相机
        const deleteOneCamera = `${url}deleteOneCamera`
        // 删除多个相机
        const deleteManyCamera = `${url}deleteManyCamera`
        // 获取引导屏列表
        const getScreenList = `${url}getScreenList`
        // 添加引导屏
        const addScreen = `${url}addScreen`
        // 删除引导屏
        const deleteOneScreen = `${url}deleteOneScreen`
        // 批量删除引导屏
        const deleteManyScreen = `${url}deleteManyScreen`
        // 获取引导屏信息
        const getScreenInfo = `${url}getScreenInfo`
        // 查找车辆
        const searchLicense = `${url}searchLicense`
        // 微信公众号查找车辆
        const searchCar = `${url}searchCar`
        
        return{
            login,
            ipInfo,
            parkingSpaceList,
            getCameraList,
            changePassword,
            getRegionList,
            addRegion,
            editRegion,
            deleteRegion,
            addCamera,
            deleteOneCamera,
            deleteManyCamera,
            getScreenList,
            addScreen,
            deleteOneScreen,
            deleteManyScreen,
            getScreenInfo,
            searchLicense,
            searchCar,
            updatePosition,
            updateSize
        }
    }
}

export default urls